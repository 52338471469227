import React from 'react';
import * as constants from '../../constants';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
// import HomePage from './home'
import '../../public/css/index.css'
import NavbarItem from '../NavbarItem';
import profileImage from '../../public/img/head_shot.png';
import ResumePage from './resume';
// import ContactPage from './contact';
import PageNotFound from './404';

class IndexPage extends React.Component {

  constructor() {
    super();
    this.state = {
    }
  }

  render() {
    return (
      <div id="website-body">
        <div id="banner">
          <div id="spacer">
            <div id="imgWrapper">
              <img src={profileImage} className="profileImg" alt={constants.fullName} />
            </div>
          </div>
        </div>
        <div className="wrapper-main">
          <Router>
            <div className="navbar">
              {/* <NavbarItem text="Home" to='/home' /> */}
              <NavbarItem text="Resum&eacute;" to="/resume" />
              {/* <NavbarItem text="Contact Me" to="/contact"/> */}
            </div>
            <div id="socials-wrapper">
              <div id="socials-container">
                <div id="socials-github">
                  <a href={constants.socials.github} target="_blank" rel="noreferrer">
                    <img src='https://github.githubassets.com/pinned-octocat.svg' className='invertColor' alt="GitHub Logo"></img>
                    <p>@Trenly</p>
                  </a>
                </div>
                <div id="socials-linkedin">
                  <a href={constants.socials.linkedin} target="_blank" rel="noreferrer">
                    <img src='https://static-exp1.licdn.com/sc/h/akt4ae504epesldzj74dzred8' alt="LinkedIn Logo"></img>
                    <p>kalebluedtke</p>
                  </a>
                </div>
                <div id="socials-stackExchange">
                  <a href={constants.socials.stackExchange} target="_blank" rel="noreferrer">
                    <img src='https://cdn.sstatic.net/Sites/stackexchange/Img/apple-touch-icon@2.png' alt="StackExchange Logo"></img>
                    <p>@Trenly</p>
                  </a>
                </div>
              </div>
            </div>
            <div className="wrapper-content">
              <Switch>
                {/* Remove this first line when using more than the resume page */}
                <Redirect exact from="/" to="/resume"/>
                {/* <Redirect exact from="/" to="/home" /> */}
                {/* <Redirect exact from="/about" to="/home"/> */}
                {/* <Route exact path="/home" component={HomePage} /> */}
                <Route path="/resume" component={ResumePage} />
                {/* <Route path="/contact" component={ContactPage}/> */}
                <Route path="/*" component={PageNotFound} />
                {/* <Route path="/*" component={PageNotFound}/> */}
              </Switch>
            </div>
          </Router>
        </div>
        <div id="footer">
          <span>{constants.copyright}</span>
        </div>
      </div>
    );
  }
}
export default IndexPage;
