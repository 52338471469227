import React from 'react';
import { NavLink } from 'react-router-dom';
import '../../public/css/404.css'

class PageNotFound extends React.Component {
  render() {
    return (
      <div className="page notFound">
        <div className="page-content">
          <h1>The page you were looking for does not exist.</h1>
          <NavLink to="/resume" className="home-button">
          {/* <NavLink to="/home" className="home-button"> */}
          <button>
            Back to Home
          </button>
          </NavLink>
        </div>
      </div>
    );
  }
}

export default PageNotFound;