import React from 'react';
import '../../public/css/resume.css'
import Resume from '../../public/assets/KalebLuedtke_Resume.pdf'
import MintLogo from '../../public/img/mint_cafe_logo.png'
import JCILogo from '../../public/img/johnson_controls_logo.png'
import MSOELogo from '../../public/img/msoe_logo.png'
import HyProLogo from '../../public/img/hypro_logo.png'
import HarleyLogo from '../../public/img/harley_logo.png'
import NorthernWireLogo from '../../public/img/northern_wire_logo.png'
import MSOESeal from '../../public/img/msoe_seal.png'
import IISELogo from '../../public/img/iise_logo.png'
import OOELogo from '../../public/img/ooe_logo.png'
import NHSLogo from '../../public/img/nhs_logo.png'
import MicrosoftMVPLogo from '../../public/img/microsoft_mvp.png'

class ResumePage extends React.Component {
  render() {
    return (
      <div className="page resume">
        <div id="download-container">
          Looking for a printer friendly version? Click here to <a href={Resume} target="_blank" rel="noreferrer noopener">view my most recent resum&eacute;</a>
        </div>
        <br />
        <div id="page-content">
          <div id="RightColumn" className="column">
            <h1 id="EducationHeading">Education</h1>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MSOELogo} alt="The MSOE Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="educationEntryTitle">Milwaukee School of Engineering</div>
                  <div className="educationEntryContent">
                    <ul>
                      <li>Master of Engineering Management</li>
                      <li>Bachelor of Science in Industrial Engineering</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h1 id="SkillsHeading">Skills</h1>
            <div>
              <ul>
                <li>Proficient in VBA, JavaScript, HTML &amp; CSS, Powershell, and Batch</li>
                <li>Experience with Java, C#, C++, Python, and SQL</li>
                <li>Familiar with Git, Azure DevOps, and Jira, especially as they relate to Project Management</li>
                <li>Project management using Scaled Agile Framework (SAFe), Kanban, and Waterfall methodologies</li>
              </ul>
            </div>      
            <h1 id="SocitiesHeading">Societies and Certifications</h1>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MicrosoftMVPLogo} alt="The Microsoft Most Valuable Professional Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="resumeEntryTitle">Microsoft Most Valuable Professional - Windows Development</div>
                  <div className="resumeEntryContent">December 2022 - Present</div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={OOELogo} alt="The OOE Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="resumeEntryTitle">Order of the Engineer</div>
                  <div className="resumeEntryContent">Inducted February 2021</div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={IISELogo} alt="The IISE Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="resumeEntryTitle">Institute of Industrial and Systems Engineers</div>
                  <div className="resumeEntryContent">September 2016 - Present</div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MSOESeal} alt="The MSOE Seal" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="resumeEntryTitle">Six Sigma Green Belt</div>
                  <div className="resumeEntryContent">Received March 2019</div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={NHSLogo} alt="The National Honor Society Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryBody">
                  <div className="resumeEntryTitle">National Honor Society</div>
                  <div className="resumeEntryContent">&emsp;Inducted April 2015</div>
                </div>
              </div>
            </div>
          </div>
          <div id="LeftColumn" className="column">
            <h1>Work Experience</h1>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={JCILogo} alt="The Johnson Controls Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">Johnson Controls - Milwaukee, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Engineering Excellence Lead (Mar 2021 - Present)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Chair the global committee for planning and execution of an annual company-wide innovation competition</li>
                      <li>Grew participation in organizational innovation activities from 105 active members to over 1,300 worldwide</li>
                      <li>Manage multiple hardware and software development teams in the implementation of IoT platforms to be used across product lines</li>
                      <li>Manage the release and requirements of equipment connectivity gateways</li>
                      <li>Work with engineering leaders across multiple business units to implement a digital connectivity strategy for all new product introduction globally</li>
                      <li>Implemented development standards for new product lines across multiple product business units</li>
                    </ul>
                  </div>
                  <div className="resumeEntryBody">
                    <div className="jobEntryTitle">Engineering Excellence Intern (Mar 2019 - Mar 2021)</div>
                    <div className="jobEntryContent">
                      <ul>
                        <li>Worked with associates in India, Asia, and around the globe to better utilize shared resources and solve problems as a unified team</li>
                        <li>Created purchase requisitions and expense reports</li>
                        <li>Assisted program leaders in business decisions to increase performance, throughput, and revenue for the department</li>
                        <li>Managed multiple projects at a high level and act as a liaison between the project leads, engineering managers, and stakeholders</li>
                        <li>Worked with management to implement a competency management program used to identify gaps in departmental knowledge and help improve long-term staffing decisions</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MintLogo} alt="The Mint Cafe Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">The Mint Cafe - Wausau, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Technology Communications Manager (Oct 2013 - Present)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Manage the implementation of the website, domain, and Google Suite</li>
                      <li>Provide technology support including system management, security code management, point-of-sale programming, and maintain current backups</li>
                      <li>Moved invoicing, accounts receivable, event scheduling, and other major business functions onto digital services enabling functional collaboration</li>
                      <li>Assisted management by setting up services allowing them to work at home</li>
                      <li>Train management and employees to use newly implemented technology</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MSOELogo} alt="The MSOE Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">Milwaukee School of Engineering - Milwaukee, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">A/V Technician (Sept 2017 - Mar 2019)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Provided support for classroom A/V systems</li>
                      <li>Assisted professors in using projection systems with their curriculum</li>
                      <li>Set up and Support on-campus events which use A/V Systems</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={HyProLogo} alt="The HyPro Inc. Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">HyPro Inc. - Rhinelander, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Machine Operator (May 2018 - Mar 2019)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Trained to run CNC Mills</li>
                      <li>Acquired some G-Code experience</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={NorthernWireLogo} alt="The Northern Wire Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">Northern Wire - Merrill, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Seasonal Machine Operator (Jun 2017 - Aug 2017)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Trained to run mechanical and hydraulic presses</li>
                      <li>Worked with production leads to ensure quality</li>
                      <li>Cross trained to run Drill Presses, CNC Benders, and Chamfers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={HarleyLogo} alt="The Harley Davidson Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">Harley Davidson - Tomahawk, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Temporary Production Technician (May 2017)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Worked on the production line washing parts</li>
                      <li>Helped maintain quality standards</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="resumeEntry">
              <div className="companyLogo">
                <img src={MintLogo} alt="The Mint Cafe Logo" />
              </div>
              <div className="resumeEntryContainer">
                <div className="resumeEntryTitle">The Mint Cafe - Wausau, WI</div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Lead Catering Assistant (Oct 2013 - Aug 2016)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Assisted management in catering operations</li>
                      <li>Planned efficient use of staff and resources</li>
                      <li>Introduced new systems for efficient food preparation</li>
                      <li>Helped in reducing average amount of labor by over 20</li>
                      <li>Gained customer service and interpersonal skills</li>
                    </ul>
                  </div>
                </div>
                <div className="resumeEntryBody">
                  <div className="jobEntryTitle">Server / Host (Oct 2013 - Aug 2016)</div>
                  <div className="jobEntryContent">
                    <ul>
                      <li>Gained interpersonal skills</li>
                      <li>Learned how to adapt under pressure</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResumePage;