import React from 'react';
import {NavLink} from 'react-router-dom'

class NavbarItem extends React.Component {
    render() {
    return(
        <div className="navitem">
          <NavLink to={this.props.to} activeStyle={{color: 'goldenrod'}}>{this.props.text}</NavLink>
        </div>
    );
  }
}

export default NavbarItem;